import cn from 'classnames';
import { useRouter } from 'next/router';
import { i18n } from 'next-i18next.config';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import Searchbar from '@components/common/Searchbar/Searchbar';
import { CartButton } from '@components/navigation/buttons/CartButton';
import { LogoButton } from '@components/navigation/buttons/LogoButton';
import { MobileMenuButton } from '@components/navigation/buttons/MobileMenuButton';
import ClickOutside from '@lib/click-outside/click-outside';
import { isStorefrontDisabled } from '@lib/storefront';
import { isLife360Enabled } from '@lib/storefront';

import { createUrlList } from '../createUrlList';
import { MobileDropDownTransitiveProps, NavigationProps } from '../types';
import { Dropdown } from './Dropdown';

import s from './MobileNavigation.module.scss';

type Props = Pick<NavigationProps, 'linksOrMenus'> & MobileDropDownTransitiveProps;

export const MobileNavigation: FC<Props> = ({ linksOrMenus, calcDropDownTop }) => {
  const { locale = i18n.defaultLocale } = useRouter();
  const hideStorefront = isStorefrontDisabled(locale);
  const list = useMemo(() => createUrlList(linksOrMenus), [linksOrMenus]);
  const [open, setOpen] = useState(false);

  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const onSearchFocus = useCallback(() => {
    setIsSearchFocused(true);
    setOpen(false);
  }, []);

  const onSearchBlur = useCallback(() => {
    setIsSearchFocused(false);
  }, []);

  const onSearchToggle = useCallback((state: boolean) => {
    if (state) {
      setOpen(false);
    }
  }, []);

  const close = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      document.body.classList.add('mobile-menu-open');
    } else {
      document.body.classList.remove('mobile-menu-open');
    }

    return () => {
      document.body.classList.remove('mobile-menu-open');
    };
  }, [open]);

  return (
    <ClickOutside active={open} onClick={close}>
      <header className="lg:hidden bg-white">
        <div>
          <nav className={cn(s.navigationBar, isLife360Enabled() ? 'items-center' : '')}>
            <MobileMenuButton
              onClick={() => {
                setOpen((v) => !v);
                setIsSearchFocused(false);
              }}
              className="justify-self-start"
            />

            <LogoButton
              className={cn(isLife360Enabled() ? s.logoLife360 : s.logoTile, 'justify-self-center p-1')}
              onClick={close}
            />

            {!hideStorefront && (
              <div className="flex flex-row items-center justify-self-end space-x-5">
                <div className={isSearchFocused ? s.isSearchFocused : s.search}>
                  <Searchbar onBlur={onSearchBlur} onFocus={onSearchFocus} onToggle={onSearchToggle} />
                </div>

                <CartButton />
              </div>
            )}
          </nav>

          {open && <Dropdown list={list} close={close} top={calcDropDownTop()} />}
        </div>
      </header>
    </ClickOutside>
  );
};
